<script setup lang="ts">
import {IconNameType} from '@toolify/server/src/enum/IconNameType'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {IconWeightType} from '../enum/IconWeightType'
import {IconWeightTypeToClassNameMap} from '../maps/IconWeightTypeToClassNameMap'
import {SizeVariantType} from '@toolify/client/src/modules/ui/enum/SizeVariantType'
import {IconBehavior} from '@toolify/client/src/modules/ui/enum/IconBehavior'

interface Props {
  name: IconNameType
  weightType?: IconWeightType
  size?: SizeVariantType
  behavior?: IconBehavior
}

const props = withDefaults(defineProps<Props>(), {
  weightType: IconWeightType.Regular,
  size: SizeVariantType.Medium,
  behavior: IconBehavior.None,
})
</script>

<template>
  <FontAwesomeIcon
    :icon="[IconWeightTypeToClassNameMap.get(props.weightType), props.name]"
    :spin="props.behavior === IconBehavior.Spin"
    :class="['icon', `size-${props.size}`]"
  />
</template>

<style scoped lang="scss">
@use '../../../styles/variables' as variables;

.icon {
  &.size-large {
    font-size: map-get(variables.$iconSizes, large);
  }

  &.size-standard {
    font-size: map-get(variables.$iconSizes, standard);
  }

  &.size-medium {
    font-size: map-get(variables.$iconSizes, medium);
  }

  &.size-small {
    font-size: map-get(variables.$iconSizes, small);
  }

  &.size-xsmall {
    font-size: map-get(variables.$iconSizes, xsmall);
  }

  color: map-get(variables.$colors, content-normal);
}
</style>

