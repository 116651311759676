<script setup lang="ts">
import LoadingComponent from '@toolify/client/src/modules/ui/components/LoadingComponent.vue'
import IconComponent from '@toolify/client/src/modules/ui/components/IconComponent.vue'
import {IconNameType} from '@toolify/server/src/enum/IconNameType'
import {SizeVariantType} from '@toolify/client/src/modules/ui/enum/SizeVariantType'
import {StyleVariantType} from '@toolify/client/src/modules/ui/enum/StyleVariantType'
import {ColorVariantType} from '@toolify/client/src/modules/ui/enum/ColorVariantType'
import {IconWeightType} from '@toolify/client/src/modules/ui/enum/IconWeightType'
import {IconBehavior} from '@toolify/client/src/modules/ui/enum/IconBehavior'

interface Props {
  style?: StyleVariantType
  color?: ColorVariantType
  iconNameType?: IconNameType
  iconWeightType?: IconWeightType
  isLoading?: boolean
  isDisabled?: boolean
  round?: boolean
  hasArrow?: boolean
  hasFocus?: boolean
  size?: SizeVariantType
  iconBehavior?: IconBehavior
}

const props = withDefaults(defineProps<Props>(), {
  style: StyleVariantType.FilledFilled,
  color: ColorVariantType.Primary,
  iconNameType: null,
  iconWeightType: IconWeightType.Regular,
  isLoading: false,
  round: false,
  hasArrow: false,
  hasFocus: false,
  isDisabled: false,
  size: SizeVariantType.Standard,
  iconBehavior: IconBehavior.None,
})
</script>

<template>
  <button
    class="button"
    :class="[
      {'is-icon-button': !$slots.default && iconNameType},
      `theme-${color}`,
      `style-${style}`,
      `size-${size}`,
      {'is-loading': isLoading, 'is-disabled': isDisabled, 'is-round': round, 'has-focus': hasFocus}
    ]"
  >
    <LoadingComponent v-if="isLoading" color="white"/>
    <IconComponent
      :name="iconNameType"
      :weightType="props.iconWeightType"
      :size="SizeVariantType.Small"
      :behavior="iconBehavior"
      v-if="iconNameType"
    />
    <div class="content" v-if="$slots.default">
      <slot/>
    </div>
    <IconComponent
      :name="IconNameType.ChevronDown"
      :size="SizeVariantType.Small"
      class="arrow"
      v-if="hasArrow"
    />
  </button>
</template>

<style scoped lang="scss">
  @use '../../../styles/variables' as variables;

  .button {
    border-radius: map-get(variables.$componentRadius, standard);
    border: none;
    padding: 0 12px;
    height: map-get(variables.$componentSizes, standard);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;

    .content{
      line-height: 100%;
    }

    &.size-medium {
      padding: 0 4px;
      font-size: 14px;
      height: map-get(variables.$componentSizes, medium);
      border-radius: map-get(variables.$componentRadius, medium);

      :deep(.icon) {
        font-size: map-get(variables.$iconSizes, medium);
      }
    }

    &.size-large {
      padding: 0 12px;
      font-size: 20px;
      height: map-get(variables.$componentSizes, large);
      border-radius: map-get(variables.$componentRadius, large);
      :deep(.icon) {
        font-size: map-get(variables.$iconSizes, large);
      }
    }

    &.is-round {
      border-radius: 100px;
      padding: 0 20px;
    }

    &.is-loading {
      .content{
        visibility: hidden;
      }

      :deep(.icon) {
        visibility: hidden;
      }
    }

    &.is-disabled {
      pointer-events: none;
      background: map-get(variables.$colors, container) !important;
      color: map-get(variables.$colors, content-dark) !important;
    }
  }

  .loading {
    position: absolute;
    width: 10px;
    height: 10px;
  }

  .has-focus.arrow {
    :deep(.icon) {
      transform: rotate(180deg);
    }
  }

  .theme-primary{
    &.style-filled-filled{
      color: map-get(variables.$colors, on-primary);
      background: map-get(variables.$colors, primary);
      :deep(.icon) {
        color: map-get(variables.$colors, on-primary)
      }

      &:hover{
        color: map-get(variables.$colors, on-primary-hover);
        background: map-get(variables.$colors, primary-hover);
        :deep(.icon) {
          color: map-get(variables.$colors, on-primary-hover)
        }
      }
    }

    &.style-clear-filled{
      color: map-get(variables.$colors, primary);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, primary)
      }

      &:hover{
        background: map-get(variables.$colors, container-high);
      }
    }

    &.style-clear-clear{
      color: map-get(variables.$colors, primary);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, primary)
      }

      &:hover{
        color: map-get(variables.$colors, primary-hover);
        :deep(.icon) {
          color: map-get(variables.$colors, primary-hover)
        }
      }
    }
  }

  .theme-error{
    &.style-filled-filled{
      color: map-get(variables.$colors, on-error);
      background: map-get(variables.$colors, error);
      :deep(.icon) {
        color: map-get(variables.$colors, on-error)
      }

      &:hover{
        background: map-get(variables.$colors, error-hover);
      }
    }

    &.style-clear-filled{
      color: map-get(variables.$colors, error);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, error)
      }

      &:hover{
        background: map-get(variables.$colors, container-high);
      }
    }

    &.style-clear-clear{
      color: map-get(variables.$colors, error);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, error)
      }

      &:hover{
        color: map-get(variables.$colors, error-hover);
        :deep(.icon) {
          color: map-get(variables.$colors, error-hover)
        }
      }
    }
  }

  .theme-neutral{
    &.style-filled-filled{
      color: map-get(variables.$colors, content-normal);
      background: map-get(variables.$colors, container-high);
      :deep(.icon) {
        color: map-get(variables.$colors, content-normal)
      }

      &:hover{
        background: map-get(variables.$colors, container-highest);
      }
    }

    &.style-clear-filled{
      color: map-get(variables.$colors, content-dim);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, content-dim);
      }

      &:hover{
        color: map-get(variables.$colors, content-normal);
        background: map-get(variables.$colors, container-high);
        :deep(.icon) {
          color: map-get(variables.$colors, content-normal);
        }
      }
    }

    &.style-clear-clear{
      color: map-get(variables.$colors, content-dim);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, content-dim)
      }

      &:hover{
        color: map-get(variables.$colors, content-normal);
        :deep(.icon) {
          color: map-get(variables.$colors, content-normal)
        }
      }
    }
  }

  .theme-dark{
    &.style-filled-filled{
      color: map-get(variables.$colors, content-normal);
      background: map-get(variables.$colors, container);
      :deep(.icon) {
        color: map-get(variables.$colors, content-normal)
      }

      &:hover{
        background: map-get(variables.$colors, container-high);
      }
    }

    &.style-clear-filled{
      color: map-get(variables.$colors, content-dark);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, content-dark)
      }

      &:hover{
        color: map-get(variables.$colors, content-normal);
        background: map-get(variables.$colors, container);
        :deep(.icon) {
          color: map-get(variables.$colors, content-normal);
        }
      }
    }

    &.style-clear-clear{
      color: map-get(variables.$colors, content-dark);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, content-dark)
      }

      &:hover{
        color: map-get(variables.$colors, content-dim);
        :deep(.icon) {
          color: map-get(variables.$colors, content-dim)
        }
      }
    }
  }

  .theme-dim{
    &.style-filled-filled{
      color: map-get(variables.$colors, content-normal);
      background: map-get(variables.$colors, container);
      :deep(.icon) {
        color: map-get(variables.$colors, content-normal)
      }

      &:hover{
        background: map-get(variables.$colors, container-high);
      }
    }

    &.style-clear-filled{
      color: map-get(variables.$colors, content-dark);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, content-dark)
      }

      &:hover{
        color: map-get(variables.$colors, content-normal);
        background: map-get(variables.$colors, container);
        :deep(.icon) {
          color: map-get(variables.$colors, content-normal);
        }
      }
    }

    &.style-clear-clear{
      color: map-get(variables.$colors, content-dim);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, content-dim);
      }

      &:hover{
        color: map-get(variables.$colors, content-normal);
        :deep(.icon) {
          color: map-get(variables.$colors, content-normal)
        }
      }
    }
  }

  .theme-darkest{
    &.style-filled-filled{
      color: map-get(variables.$colors, content-normal);
      background: map-get(variables.$colors, container-low);
      :deep(.icon) {
        color: map-get(variables.$colors, content-normal)
      }

      &:hover{
        background: map-get(variables.$colors, container);
      }
    }

    &.style-clear-filled{
      color: map-get(variables.$colors, content-dark);
      background: transparent;
      :deep(.icon) {
        color: map-get(variables.$colors, content-dark)
      }

      &:hover{
        color: map-get(variables.$colors, content-normal);
        background: map-get(variables.$colors, container);
        :deep(.icon) {
          color: map-get(variables.$colors, content-normal);
        }
      }
    }
  }

  .is-icon-button{
    padding: 0;

    &.size-large{
      width: map-get(variables.$componentSizes, large);
      height: map-get(variables.$componentSizes, large);
      border-radius: map-get(variables.$componentRadius, large);
      :deep(.icon) {
        font-size: map-get(variables.$iconSizes, large);
      }
    }

    &.size-standard{
      width: map-get(variables.$componentSizes, standard);
      height: map-get(variables.$componentSizes, standard);
      border-radius: map-get(variables.$componentRadius, standard);
      :deep(.icon) {
        font-size: map-get(variables.$iconSizes, medium);
      }
    }

    &.size-medium{
      font-size: map-get(variables.$iconSizes, medium);
      width: map-get(variables.$componentSizes, medium);
      height: map-get(variables.$componentSizes, medium);
      border-radius: map-get(variables.$componentRadius, medium);
      :deep(.icon) {
        font-size: map-get(variables.$iconSizes, medium);
      }
    }

    &.size-small{
      width: map-get(variables.$componentSizes, small);
      height: map-get(variables.$componentSizes, small);
      border-radius: map-get(variables.$componentRadius, small);
      :deep(.icon) {
        font-size: map-get(variables.$iconSizes, small);
      }
    }

    &.theme-primary{
      &.style-clear-filled{
        background: transparent;
        :deep(.icon) {
          color: map-get(variables.$colors, on-primary)
        }

        &:hover{
          background: map-get(variables.$colors, primary);
          :deep(.icon) {
            color: map-get(variables.$colors, on-primary-hover);
          }
        }
      }

      &.style-clear-clear{
        background: transparent;
        :deep(.icon) {
          color: map-get(variables.$colors, on-primary)
        }

        &:hover{
          :deep(.icon) {
            color: map-get(variables.$colors, on-primary-hover)
          }
        }
      }
    }

    &.theme-dark{
      &.style-filled-filled{
        background: map-get(variables.$colors, container-low);
        :deep(.icon) {
          color: map-get(variables.$colors, content-normal)
        }

        &:hover{
          background: map-get(variables.$colors, container);
        }
      }

      &.style-clear-clear{
        background: transparent;
        :deep(.icon) {
          color: map-get(variables.$colors, content-dark)
        }

        &:hover{
          :deep(.icon) {
            color: map-get(variables.$colors, content-dim)
          }
        }
      }
    }
  }
</style>
